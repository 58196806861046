import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { HiLocationMarker, HiPhone, HiMail } from "react-icons/hi";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Section from "components/common/Section/Index";
import Paragraph from "components/common/Paragraph";
import Text from "components/common/Text";
import Icon from "components/common/Icon";
import Nav from "components/common/Nav";

const ContactHomepageSection = () => {
  const { t } = useTranslation();

  const CONTACT_TYPE_LOCATION = "location";
  const CONTACT_TYPE_PHONE = "phone";
  const CONTACT_TYPE_MAIL = "mail";

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { id: { eq: "homepage" } } }) {
        ...HomepageContact
      }
    }
  `);

  const { title, text1, contactMethods } =
    data.markdownRemark.frontmatter.contact;

  return (
    <Section id="contact" className="relative overflow-hidden">
      <Section.Container className="m-auto my-64 md:my-72 3xl:my-128 px-4 md:px-2 lg:px-4 text-center">
        <Text
          className="block leading-normal xl:leading-normal font-medium 2xl:leading-normal xl:text-6xl mb-6"
          size="4xl"
        >
          {t(title)}
        </Text>
        <Paragraph>{t(text1)}</Paragraph>
        <Nav
          vertical
          className="mt-24 w-full md:w-116 max-w-full m-auto"
          listClassName="gap-5"
        >
          {contactMethods.map((contactMethod) => (
            <Nav.Link
              key={contactMethod.text}
              to={contactMethod.link}
              target="_blank"
              className="btn group transition-all duration-150 hover:bg-primary hover:bg-opacity-20 hover:border-primary flex flex-row items-center gap-6 px-6 py-6 bg-mine-shaft border border-gray rounded-lg border-opacity-20 text-left"
            >
              {contactMethod.type === CONTACT_TYPE_LOCATION && (
                <Icon color="primary">
                  <HiLocationMarker size="1.65em" />
                </Icon>
              )}
              {contactMethod.type === CONTACT_TYPE_PHONE && (
                <Icon color="primary">
                  <HiPhone size="1.65em" />
                </Icon>
              )}
              {contactMethod.type === CONTACT_TYPE_MAIL && (
                <Icon color="primary">
                  <HiMail size="1.65em" />
                </Icon>
              )}
              <Text size="sm" className="md:text-base">
                {t(contactMethod.text)}
              </Text>
            </Nav.Link>
          ))}
        </Nav>
        <div className="absolute top-0 left-0 w-full h-full -z-10 select-none">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <radialGradient id="contactGradient1">
                <stop offset="30%" stopColor="rgba(121,95,255,0.5)" />
                <stop offset="160%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="contactGradient2">
                <stop offset="10%" stopColor="rgba(76,187,181,0.5)" />
                <stop offset="90%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="contactGradient3">
                <stop offset="10%" stopColor="rgba(76,187,181,0.5)" />
                <stop offset="95%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
            </defs>
            <circle
              style={{
                opacity: 0.165,
              }}
              cx="15"
              cy="2.25"
              r="2.75"
              fill="url('#contactGradient1')"
            />
            <circle
              style={{
                opacity: 0.145,
              }}
              cx="13"
              cy="3"
              r="2"
              fill="url('#contactGradient2')"
            />
            <circle
              style={{
                opacity: 0.125,
              }}
              cx="5"
              cy="10"
              r="7"
              fill="url('#contactGradient3')"
            />
          </svg>
        </div>
      </Section.Container>
    </Section>
  );
};

export default ContactHomepageSection;
