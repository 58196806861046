import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Page from "components/Page";
import Layout from "components/Layout";
import Intro from "components/sections/homepage/Intro";
import Services from "components/sections/homepage/Services";
import AboutUs from "components/sections/homepage/AboutUs";
import Partners from "components/sections/homepage/Partners";
import Contact from "components/sections/homepage/Contact";

import colors from "constants/colors";

const Homepage = ({ data }) => {
  const { t } = useTranslation();
  const { title, description } = data.markdownRemark.frontmatter.page;

  return (
    <Page
      id="homepage"
      metadata={{
        title: t(title),
        description: t(description),
      }}
    >
      <Layout
        stickyHeader={true}
        palette={{
          background: colors["cod-gray"],
          primary: colors["fountain-blue"],
          secondary: colors.white,
        }}
      >
        <Intro />
        <Services />
        <AboutUs />
        <Partners />
        <Contact />
      </Layout>
    </Page>
  );
};

export default Homepage;

export const query = graphql`
  query ($language: String!) {
    markdownRemark(frontmatter: { page: { id: { eq: "homepage" } } }) {
      ...PageInformation
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
