import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Section from "components/common/Section/Index";
import Paragraph from "components/common/Paragraph";
import Text from "components/common/Text";
import { graphql, useStaticQuery } from "gatsby";

const PartnersHomepageSection = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { id: { eq: "homepage" } } }) {
        ...HomepagePartners
      }
    }
  `);

  const { title, text1, text2 } = data.markdownRemark.frontmatter.ourPartners;

  return (
    <Section id="our-partners">
      <Section.Container className="m-auto my-24 md:my-48 py-24 px-4 md:px-2 lg:px-4">
        <div className="flex flex-col gap-14 lg:flex-row">
          <div className="col w-full lg:w-5/12 xl:w-3/12">
            {title && (
              <Text
                className="block leading-normal xl:leading-normal font-medium 2xl:leading-normal xl:text-6xl my-2 mb-14"
                size="4xl"
              >
                {t(title)}
              </Text>
            )}
            {text1 && <Paragraph className="leading-8">{t(text1)}</Paragraph>}
            {text2 && (
              <Paragraph className="mt-14 leading-8">{t(text2)}</Paragraph>
            )}
          </div>
          <div className="col w-full lg:w-7/12 xl:w-9/12 mt-16 md:mt-34 lg:mt-96 text-right">
            <StaticImage
              src="../../../images/vector-brands.png"
              alt="Brands"
              placeholder="none"
            />
          </div>
        </div>
      </Section.Container>
    </Section>
  );
};

export default PartnersHomepageSection;
