import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Section from "components/common/Section/Index";
import Paragraph from "components/common/Paragraph";
import Text from "components/common/Text";

const AboutUsHomepageSection = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { id: { eq: "homepage" } } }) {
        ...HomepageAboutUs
      }
    }
  `);

  const { title, text1, text2, text3 } =
    data.markdownRemark.frontmatter.aboutUs;

  return (
    <Section id="about-us" className="relative">
      <Section.Container className="m-auto py-24 lg:my-48 my-24">
        <div className="w-full px-4">
          {title && (
            <Text
              className="leading-normal xl:leading-normal font-medium 2xl:leading-normal xl:text-6xl my-2 mb-14"
              size="4xl"
              headlineTag="h2"
            >
              {t(title)}
            </Text>
          )}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 lg:gap-24">
            {text1 && <Paragraph className="leading-8">{t(text1)}</Paragraph>}
            {text2 && <Paragraph className="leading-8">{t(text2)}</Paragraph>}
            {text3 && <Paragraph className="leading-8">{t(text3)}</Paragraph>}
          </div>
        </div>
        <div className="absolute hidden md:block top-0 right-0 w-full h-full select-none -z-10">
          <svg
            className="absolute"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <radialGradient id="aboutUsGradient1">
                <stop offset="30%" stopColor="rgba(121,95,255,0.5)" />
                <stop offset="160%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="aboutUsGradient2">
                <stop offset="10%" stopColor="rgba(76,187,181,0.5)" />
                <stop offset="90%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="aboutUsGradient3">
                <stop offset="5%" stopColor="rgba(121,95,255,0.5)" />
                <stop offset="160%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
            </defs>
            <circle
              style={{
                opacity: 0.125,
              }}
              cx="19"
              cy="14.25"
              r="5.75"
              fill="url('#aboutUsGradient1')"
            />
            <circle
              style={{
                opacity: 0.155,
              }}
              cx="20"
              cy="16.95"
              r="4.25"
              fill="url('#aboutUsGradient2')"
            />
            <circle
              style={{
                opacity: 0.125,
              }}
              cx="0"
              cy="10"
              r="7"
              fill="url('#aboutUsGradient3')"
            />
          </svg>
          <StaticImage
            style={{
              transform: "translate(0, -35rem)",
            }}
            height={3000}
            src="../../../images/vector-lines-001.svg"
            alt="Vector lines"
            placeholder="none"
          />
        </div>
      </Section.Container>
    </Section>
  );
};

export default AboutUsHomepageSection;
