import React from "react";
import { GoLinkExternal } from "react-icons/go";
import { graphql, useStaticQuery } from "gatsby";
import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Icon from "components/common/Icon";
import Section from "components/common/Section/Index";
import Text from "components/common/Text";
import Link from "components/common/Link";
import Paragraph from "components/common/Paragraph";

const Services = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { id: { eq: "homepage" } } }) {
        ...HomepageServices
      }
    }
  `);

  const { tabs, title } = data.markdownRemark.frontmatter.services;

  return (
    <Section id="our-services">
      <Section.Container className="py-36 md:py-64 lg:py-96 m-auto px-4 md:px-2 lg:px-4 break-normal">
        <Text
          className="leading-normal xl:leading-normal font-medium 2xl:leading-normal xl:text-6xl my-2 mb-12"
          headlineTag="h2"
          size="4xl"
        >
          {t(title)}
        </Text>
        <div className="services h-auto min-h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-2">
          {tabs.map((serviceTab, index) => (
            <div
              key={serviceTab.title}
              className={classNames(
                "service-tab group rounded-md lg:rounded-none lg:rounded-r-none transition-all duration-200 ease-in hover:z-10 border border-mine-shaft h-auto lg:h-64 xl:h-128 min-h-full flex flex-col justify-between bg-mine-shaft p-6 md:pr-8 xl:pr-24",
                {
                  "lg:rounded-l-xl": index === 0,
                  "lg:rounded-r-xl": index === tabs.length - 1,
                }
              )}
            >
              {serviceTab.title && (
                <Text className="mb-6 leading-normal" size="2xl">
                  {t(serviceTab.title)}
                </Text>
              )}
              <div className="flex flex-col gap-16">
                {serviceTab.description && (
                  <Paragraph className="h-16 text-gray text-base">
                    {t(serviceTab.description)}
                  </Paragraph>
                )}
                {serviceTab.link && (
                  <Link
                    className="transition-all text-primary-light hover:opacity-80 flex gap-2 flex-row items-center"
                    href={serviceTab.link}
                    fit
                  >
                    <Text size="sm">{t("Contact with us")}</Text>
                    <Icon>
                      <GoLinkExternal />
                    </Icon>
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="absolute top-0 left-0 w-full h-full -z-10">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <radialGradient id="servicesGradient1">
                <stop offset="10%" stopColor="rgba(76,187,181,0.5)" />
                <stop offset="90%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="servicesGradient2">
                <stop offset="30%" stopColor="rgba(121,95,255,0.5)" />
                <stop offset="160%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
            </defs>
            <circle
              style={{
                opacity: 0.125,
              }}
              cx="19"
              cy="2.25"
              r="2.75"
              fill="url('#servicesGradient1')"
            />
            <circle
              style={{
                opacity: 0.115,
              }}
              cx="17"
              cy="3.35"
              r="3.25"
              fill="url('#servicesGradient2')"
            />
          </svg>
        </div>
      </Section.Container>
    </Section>
  );
};

export default Services;
