import { cloneElement } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Icon = ({ className, children, color }) =>
  cloneElement(children ?? children?.[0], {
    className: classNames("Icon", className, {
      [`text-${color}`]: color,
    }),
    "aria-hidden": true,
  });

Icon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

Icon.defaultProps = {
  className: "",
  color: "text-white",
};

export default Icon;
