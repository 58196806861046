import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Section from "components/common/Section/Index";
import Text from "components/common/Text";
import { graphql, useStaticQuery } from "gatsby";

const IntroHomepageSection = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { id: { eq: "homepage" } } }) {
        ...HomepageIntro
      }
    }
  `);

  const { short, title } = data.markdownRemark.frontmatter.intro;

  return (
    <Section id="home">
      <Section.Container className="m-auto px-4 md:px-2 lg:px-4 overflow-x-hidden overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-full -z-10">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <radialGradient id="introGradient1">
                <stop offset="10%" stopColor="rgba(76,187,181,0.5)" />
                <stop offset="90%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="introGradient2">
                <stop offset="30%" stopColor="rgba(121,95,255,0.5)" />
                <stop offset="160%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="introGradient3">
                <stop offset="10%" stopColor="rgba(76,187,181,0.5)" />
                <stop offset="95%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="introGradient4">
                <stop offset="5%" stopColor="rgba(121,95,255,0.5)" />
                <stop offset="160%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
            </defs>
            <circle
              style={{
                opacity: 0.125,
              }}
              cx="2"
              cy="1.25"
              r="12.75"
              fill="url('#introGradient1')"
            />
            <circle
              style={{
                opacity: 0.115,
              }}
              cx="7"
              cy="4"
              r="3.25"
              fill="url('#introGradient2')"
            />
            <circle
              style={{
                opacity: 0.145,
              }}
              cx="15"
              cy="0"
              r="4"
              fill="url('#introGradient3')"
            />
            <circle
              style={{
                opacity: 0.135,
              }}
              cx="19"
              cy="4"
              r="7"
              fill="url('#introGradient4')"
            />
          </svg>
          <div className="absolute top-0 right-0 select-none">
            <StaticImage
              height={3000}
              src="../../../images/vector-lines-000.svg"
              alt="Vector lines"
              placeholder="none"
            />
          </div>
        </div>
        <div className="py-24 md:py-40 3xl:py-64 relative">
          <div>
            <div className="w-11/12 md:w-3/4 xl:w-3/6 2xl:w-4/6">
              {short && (
                <Text
                  color="primary"
                  className="block mb-8 font-medium"
                  size="lg"
                >
                  {t(short)}
                </Text>
              )}
              {title && (
                <Text
                  className="md:text-5xl leading-normal md:leading-normal xl:leading-normal font-medium 2xl:leading-normal xl:text-6xl 2xl:text-7xl my-2 text-primary-light"
                  size="4xl"
                  headlineTag="h1"
                >
                  {t(title)}
                </Text>
              )}
            </div>
          </div>
          <div className="absolute bottom-0 flex flex-row gap-4">
            <div className="relative before-content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full active w-20 h-0.5 bg-primary rounded-full" />
          </div>
        </div>
      </Section.Container>
    </Section>
  );
};

export default IntroHomepageSection;
